import { Frame } from '@xing-com/crate-lebenslauf-xinglets';
import type { Xinglet } from '@xing-com/crate-xinglet';

import { Startpage } from './startpage';

export default class StartpageXinglet implements Xinglet {
  public getComponent() {
    return (): JSX.Element => (
      <Frame noMainContentWrapperStyles>
        <Startpage />
      </Frame>
    );
  }
}
